@import url(npm:uplot/dist/uPlot.min.css);
@import url(../css/icons.css);

* {
    margin: 0;
    padding: 0;
}

:root {
  --color-dark-gray: #A3A3A3;
  --color-gold: #FFD700;
  --color-dark-purple: #6c5ce7;
  --color-light-purple: #a29bfe;
  --color-dark-blue: #0984e3;
  --color-light-blue: #74b9ff;

  --theme-color-dark: #3B3738;
  --theme-color-light: #EBEBEB;

  --border-radius: 1px;

  /* Light theme */
  --color-purple: var(--color-light-purple);
  --color-blue: var(--color-light-blue);
  --color-blue-inverted: var(--color-dark-blue);
  --background-color: var(--theme-color-light);
  --text-decoration-color: var(--theme-color-dark);
  --text-color: #000;
  --text-color-inverted: #FFF;
}

body {
  background: #1c1b1c;
  color: #FFF;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: #0a0d11;
}

::-webkit-scrollbar-thumb {
  background: #FFC947;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: #e9e581;
}

@media (prefers-color-scheme: dark) {
  :root {
    --color-purple: var(--color-dark-purple);
    --color-blue: var(--color-dark-blue);
    --color-blue-inverted: var(--color-light-blue);
    --background-color: var(--theme-color-dark);
    --text-decoration-color: var(--theme-color-light);
    --text-color: #FFF;
    --text-color-inverted: #000;
  }

  body {
    background: #1c1b1c;
    color: #FFF;
  }
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: 300;
  min-width: 800px;
  height: 100%;
}

/* Page layout */
html, body {
  /* height: 100%; */
  background: #1c1b1c;
  background-image: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url(../images/background.webp);
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
}

a {
  text-decoration: none;
  color: var(--color-blue);
}

a:hover {
  color: var(--background-color);
  cursor: pointer;
}

#push {
  display: none;
  position: relative;
  min-height: 100%;
}

strong {
    font-weight: 700;
}

/* Logo */

.logo {
  display: inline-block;
}

/* Header */
header {
  overflow: auto;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
}

.header-items {
  background: #131416de;
  width: 60%;
  border-radius: 0 0 5px 5px;
  padding: 15px;
}

header .column-left {
  float: left;
}

header .column-right {
  float: right;
}

header .logo-image {
  --fixed-logo-image-size: 40px;
  width: var(--fixed-logo-image-size);
  height: var(--fixed-logo-image-size);
  border-radius: 5px;
}

header .logo-text {
  font-size: 26px;
  margin: -6px 0;
  text-transform: uppercase;
}

header .logo-data {
  display: inline-block;
}

header .logo-status {
  font-size: 16px;
}

header .header-button {
  color: var(--text-color);
  text-align: center;
  line-height: 20px;
  font-size: 14px;
  padding: 10px;
  border-radius: 5px;
}

header .header-button > span:first-of-type {
  display: inline-block;
}

header .header-button-single {
  display: none;
  cursor: pointer;
}

header .header-button-single:hover {
  background: var(--background-color) !important;
}

/* Footer */
footer {
  display: none;;
  background: var(--background-color);
  color: var(--text-color);
  border-top-right-radius: var(--border-radius);
  border-top-left-radius: var(--border-radius);
  padding: 10px 0;
  text-align: center;
  width: 90%;
  margin: 15px auto 0 auto;
}

footer a {
  color: var(--color-blue-inverted);
}

footer a:hover {
  color: var(--text-color);
}

/* Status overly */
#status-overlay {
  padding: 20px 0;
  background: #131416de;
  color: var(--text-color);
  border-radius: 5px;
  text-align: center;
  height: 160px;
  width: 350px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -95px;
  margin-left: -175px;
}

#status-overlay .logo-image {
  --fixed-logo-image-size: 72px;
  width: var(--fixed-logo-image-size);
  height: var(--fixed-logo-image-size);
}

/* Floating tooltip */
#tooltip {
  display: none;
  position: absolute;
  padding: 5px 8px;
  border-radius: var(--border-radius);
  background: var(--background-color);
  color: var(--text-color);
  z-index: 10000;
}

/* Server listing */
#server-list {
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#rec-server {
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

#rec-server h1 {
  text-transform: uppercase;
  font-size: 26px;
  background: #131416de;
  padding: 10px 10px 0 10px;
  border-radius: 5px 5px 0 0;
}

.server {
  background: #131416de;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 0 5px 10px 5px;
  width: 800px;
  display: inline-block;
}

.server .column {
  float: left;
}

.server .column-favicon {
  width: 80px;
}

.server .column-favicon .server-favicon {
  --fixed-server-favicon-size: 64px;
  width: var(--fixed-server-favicon-size);
  height: var(--fixed-server-favicon-size);
  border-radius: var(--border-radius);
  margin-top: 25px;
}

.server .column-favicon .server-rank {
  display: block;
  width: 64px;
  text-align: center;
}

.server .column-status {
  width: 282px;
}

.server .column-status .server-name {
  display: inline-block;
}

.server .column-status .server-error {
  display: none;
  color: #e74c3c;
}

.server .column-status .server-label {
  color: var(--color-dark-gray);
  font-size: 16px;
  display: none;
}

.server .column-status [id^="server-address_"] {
  color: #FFC947;
  cursor: pointer;
  text-decoration: underline;
}

.server .column-status .server-value {
  color: var(--color-dark-gray);
  font-size: 16px;
}

.server .column-graph {
  height: 100px;
  width: 400px;
}

/* Favorite icons */
.server-is-favorite {
  cursor: pointer;
  color: var(--color-gold);
}

.server-is-favorite:hover::before {
  content: "\f006";
}

.server-is-not-favorite {
  cursor: pointer;
  color: var(--background-color);
}

.server-is-not-favorite:hover {
  color: var(--color-gold);
}

/* Highlighted values */
.server-highlighted-label {
  font-size: 18px;
}

.server-highlighted-value {
  font-size: 18px;
  font-weight: 700;
}

/* Global stats */
.global-stat {
  font-weight: 700;
}

/* Sort by */
#sort-by {
  background: var(--color-purple);
}

/* Settings toggle */
#settings-toggle {
  background: var(--color-blue);
}

/* Historical graph */

.graph-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#big-graph {
  display: none;
  background-color: #131416de;
  padding: 10px;
  border-radius: 5px 5px 0 0;
}

#big-graph-checkboxes > table {
    width: 100%;
}

#big-graph {
  margin: 0 auto;
}

#big-graph-controls {
  margin: 0 auto 20px auto;
  display: none;
}

.graph-controls-show {
  color: var(--text-color);
}

#big-graph-controls .icon-star {
  color: var(--color-gold);
}

#big-graph-controls-drawer {
  background: #131416de;
  color: var(--text-color);
  border-radius: 0 5px 0 5px;
  overflow: auto;
  display: none;
  padding: 10px;
}

#big-graph-controls-drawer .graph-controls-setall {
  text-align: center;
  display: block;
  margin: 15px 0;
}

#big-graph-checkboxes {
  margin: 15px auto 0 auto;
}

/* Basic elements */
.button {
  background: var(--color-blue);
  border-radius: var(--border-radius);
  font-size: 16px;
  padding: 5px 10px;
}

.button:hover {
  background: var(--text-color);
  color: var(--text-color-inverted);
}

/* Percentage bar */
#perc-bar {
  height: 35px;
  position: relative;
  overflow-x: hidden;
}

#perc-bar .perc-bar-part {
  height: 100%;
  display: inline-block;
  position: absolute;
}

/* Header rows */
@media only screen and (max-width: 1050px) {
  .header-items {
    width: 70%;
  }
}

/* uPlot.css overrides */
.uplot .select {
  background: var(--color-blue);
  opacity: 0.3;
}